@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@keyframes fall {
  0% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: translateY(100vh) scale(1.5);
  }
}

.balloon {
  font-size: 2rem; /* Adjust the size of the emoji */
  opacity: 0;
  position: fixed;
  top: 0;
  pointer-events: none;
  animation: fall 4s linear forwards;
}